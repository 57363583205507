/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import useSWRMutation from 'swr/mutation'
import type {
  SWRMutationConfiguration
} from 'swr/mutation'
import type {
  ErrorInternalServerResponse,
  ErrorUnauthenticatedResponse,
  ErrorValidationResponse,
  LoginRequest,
  LoginToken
} from '../schema'
import { customInstance } from '../mutator/custom-instance';



  
  /**
 * メールアドレスとパスワードでログイン処理を行う。Bearer Token が返却されます。
 * @summary ログイン処理
 */
export const login = (
    loginRequest: LoginRequest,
 ) => {
      return customInstance<LoginToken>(
      {url: `/v1/auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginRequest
    },
      );
    }
  


export const getLoginMutationFetcher = ( ) => {
  return (_: string, { arg }: { arg: LoginRequest }): Promise<LoginToken> => {
    return login(arg);
  }
}
export const getLoginMutationKey = () => `/v1/auth/login` as const;

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>
export type LoginMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorInternalServerResponse

/**
 * @summary ログイン処理
 */
export const useLogin = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorInternalServerResponse>(
   options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof login>>, TError, string, LoginRequest, Awaited<ReturnType<typeof login>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getLoginMutationKey();
  const swrFn = getLoginMutationFetcher();

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
